import { AttendanceFormat, Content } from '@gn/db/client';
import fetch from 'node-fetch';
import { vimeoAccessToken } from 'portal/configs';
import { TicketAccess } from '../../tito/types';
import { AccessType, ContentCategory, ContentPublicationStatus } from '../../type';
import { getLastIdFromURL } from '../../utils/url';

export function getPublicationStatus(
    content: Pick<Content, 'access' | 'category' | 'videoUrl' | 'endDate' | 'publishDate' | 'startDate' | 'format'>,
): ContentPublicationStatus {
    const { access, category, videoUrl, endDate, publishDate, startDate } = content;
    const now = new Date();

    if (access === AccessType.Draft) {
        return ContentPublicationStatus.Draft;
    }
    if (category === ContentCategory.Article || category === ContentCategory.Announcement) {
        return ContentPublicationStatus.Published;
    }

    const isAvailableByDate = startDate
        ? startDate < now
        : (publishDate && publishDate < now) || (endDate && endDate < now);

    if (isAvailableByDate) {
        if (videoUrl) {
            return ContentPublicationStatus.Published;
        }
        if (
            content.category === ContentCategory.Workshop &&
            (content.format === 'Hybrid' || content.format === 'InPerson')
        ) {
            return ContentPublicationStatus.Finished;
        }
        return ContentPublicationStatus.Pending;
    }

    return ContentPublicationStatus.Upcoming;
}

type UserWithGroups = {
    ticketGroups?:
        | Array<{ ticket: string; groupName?: string | null; format?: AttendanceFormat | null; eventId: number | null }>
        | undefined;
};

export function hasFullTicket(user: UserWithGroups, eventId: number): boolean {
    return !!user.ticketGroups && user.ticketGroups.some((group) => group.groupName === `full-${eventId}`);
}

export function getFullTicket(user: UserWithGroups, eventId: number) {
    return !!user.ticketGroups ? user.ticketGroups.find((group) => group.groupName === `full-${eventId}`) : null;
}

export function hasWorkshopPass(user: UserWithGroups, eventId: number) {
    return (
        !!user.ticketGroups &&
        user.ticketGroups.some((group) => group.groupName === TicketAccess.WorkshopPass && group.eventId === eventId)
    );
}

export async function fetchVideoThumbnails(videoURL: string, timeout = 1000) {
    const videoId = getLastIdFromURL(videoURL);
    const url = `https://api.vimeo.com/videos/${videoId}?fields=pictures.sizes.link`;
    try {
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                Authorization: `bearer ${vimeoAccessToken}`,
                Accept: 'application/vnd.vimeo.*+json;version=3.4',
            },
            timeout,
        });
        if (!response.ok) {
            return null;
        }

        const pictureData = await response.json();
        return pictureData.pictures.sizes.map((link) => link.link) as string[];
    } catch (error) {
        throw error;
    }
}

export const replaceContentsWithLocalizedVersions = ({ contents, currentLang }) => {
    if (!currentLang) {
        return contents;
    }

    return contents
        .map((content) => {
            const localized = content.localizations.find((localization) => localization.lang === currentLang);
            if (localized) {
                return { ...content, title: localized.title, text: localized.text, slug: localized.slug };
            }
        })
        .filter(Boolean);
};

export async function fetchSensitiveVideoData(videoURL: string, timeout = 1000) {
    // IMPORTANT: The "files" field contains direct links with access tokens.
    // These should be used only for Googlebot and must not be made public.
    const videoId = getLastIdFromURL(videoURL);
    const url = `https://api.vimeo.com/videos/${videoId}?fields=pictures.sizes.link,files`;
    try {
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                Authorization: `bearer ${vimeoAccessToken}`,
                Accept: 'application/vnd.vimeo.*+json;version=3.4',
            },
            timeout,
        });
        if (!response.ok) {
            return null;
        }

        const videoData = await response.json();
        const pictureData = videoData.pictures.sizes.map((link) => link.link) as string[];
        return { thumbnailUrls: pictureData, files: videoData.files };
    } catch (error) {
        throw error;
    }
}

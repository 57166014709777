import { AttendanceFormat } from '@gn/db/client';

export interface TitoEventId {
    event: string;
    owner: string;
}

export interface EventTicketsData {
    fields: {
        attendeesCount: number;
        totalMoney: number;
        tag: string;
    };
    ticketTypeTag: string;
}

export interface TicketDataInflux {
    attendeesCount: number;
    totalMoney: number;
    tag: string;
    event: string;
    brand: string;
    ticketType: string;
    time: {
        _nanoISO: string;
        getNanoTime: () => number;
        getTime: () => number;
        toNanoISOString: () => string;
    };
}

export interface RegistrationDataInflux {
    event: string;
    brand: string;
    ticketType: string;
    status: string;
    count: number;
    time: {
        _nanoISO: string;
        getNanoTime: () => number;
        getTime: () => number;
        toNanoISOString: () => string;
    };
}

export interface TicketData {
    price: number;
    state: string;
    release_id: number;
    title: string;
}

export interface TitoReportItem {
    Ticket: string;
    Free: number;
    'Paid in full': number;
    'Paid with discount': number;
    Total: number;
    Gross: number;
}

export interface TicketType {
    id: number;
    name: string;
    titoId?: number | null;
    ticketPrice: number | null;
}

export interface TimeSeriesDataPoint {
    group: string;
    date: number;
    value: Record<string, any>;
    surplus?: number;
    sorting?: number;
}

export interface TicketTimeSeriesDataPoint extends TimeSeriesDataPoint {
    isFree: boolean;
    value: {
        attendeesCount: number;
        tag: string;
        totalMoney: number;
    };
}

export interface RegistrationTimeSeriesDataPoint extends TimeSeriesDataPoint {
    value: {
        count: number;
    };
}

export interface TitoRelease {
    id: number;
    title: string;
    slug: string;
    metadata?: ReleaseMetadata;
    price?: number;
}

export const TicketAccess = {
    Full: 'full',
    WorkshopPass: 'workshop-pass',
} as const;
export type TicketAccess = typeof TicketAccess[keyof typeof TicketAccess];

export interface ParsedMetadata {
    attendanceFormat: AttendanceFormat;
    access: TicketAccess | null;
    combo: number[];
}

export interface ReleaseMetadata {
    isHybrid?: boolean;
    isRemote?: boolean;
    isInPerson?: boolean;
    isFullAccess?: boolean;
    isPartialAccess?: boolean;
    isWorkshop?: boolean;
    combo?: number[];
    comboIds?: number[];
}

export type TitoWebhookTicketPayload = {
    _type: 'ticket';
    state_name: 'complete' | 'void';
    id: number;
    test_mode: boolean;
    updated_at: string;
    release_id: number;
    release_title: string;
    slug: string;
    price?: number | null;
    name?: string | null;
    first_name?: string | null;
    last_name?: string | null;
    email: string;
    githubUserName?: string | null;
    company_name?: string | null;
    responses?: {
        [key: string]: string;
    } | null;
    event: TitoEventRaw;
    registration: TitoRegistrationRaw;
    release: TitoReleaseRaw;
};
export type TitoWebhookRegistrationPayload = {
    _type: 'registration';
    id: number;
    test_mode: boolean;
    updated_at: string;
    slug: string;
    name?: string | null;
    first_name?: string | null;
    last_name?: string | null;
    email: string;
    company_name?: string | null;
    event: TitoEventRaw;
    tickets: unknown[];
    line_items: unknown[];
};
export type TitoWebhookPayloadRaw = TitoWebhookTicketPayload | TitoWebhookRegistrationPayload;

export interface TitoWebhookPayload {
    client: {
        name: string;
        email: string;
        githubUserName?: string | null;
        company?: string | null;
        country?: string | null;
        companyRole?: string | null;
    };
    event: {
        slug: string;
    };
    ticket: {
        titoId: number;
        titoUpdatedAt: string;
        registrationSlug: string;
        titoReleaseId: number;
        name: string;
        price: number | null;
        slug: string;
    };
}

export interface TitoEventRaw {
    title: string;
    url: string;
    slug: string;
}

export type TitoReleaseRaw = {
    id: number;
    slug: string;
    title: string;
    price: number;
    tickets_count: number;

    metadata: Record<string, string>;
    created_at: string;
    updated_at: string;
};

export interface TitoRegistrationRaw {
    id: number;
    slug: string;

    name: string;
    email: string;
    billing_address: string;
    company_name: string;
    job_title: string;

    created_at: string;
    updated_at: string;

    state: 'incomplete' | 'complete' | 'cancelled';
    tickets_count: number;
    total: number;
    free: boolean;
}

export type TitoTicketRaw = {
    id: number;
    slug: string;
    price: number;
    state: string;

    name: string | null;
    first_name: string | null;
    last_name: string | null;
    company_name: string | null;
    job_title: string | null;
    email: string | null;

    registration_id: number;
    registration_name: string;
    registration_email: string;

    release_id: number;
    release_title: string;
    release_archived: boolean;

    metadata: Record<string, string>;
    created_at: string;
    updated_at: string;
};

export type TitoDetailedTicketRaw = TitoTicketRaw & {
    release: TitoReleaseRaw;
    registration: TitoRegistrationRaw;
};

export type DiscountCode = {
    id: number;
    code: string;
    end_at: string;
    start_at: string;
    value: string;
    type: 'PercentOffDiscountCode';
    description_for_organizer?: string;
};
